import 'focus-visible/dist/focus-visible';
import React from 'react';
import { render } from 'react-dom';
import { Alert } from '../../CommonComponents/OPL/Alert/Alert';
import { Button } from '../../CommonComponents/OPL/Button/Button';
import { Input } from '../../CommonComponents/OPL/Input/Input';
import { Link } from '../../CommonComponents/OPL/Link/Link';
import { Text } from '../../CommonComponents/OPL/Text/Text';
import { AuthWrapper } from '../components/AuthWrapper/AuthWrapper';
import { LoginFormStep } from './Login.meta';
import { useLogin } from './useLogin';
import { TwoFactorAuth } from './components/TwoFactorAuth/TwoFactorAuth';
import { ToastContainer } from '../../CommonComponents/OPL/Toast/Toast';
import { RedirectModal } from './components/RedirectModal/RedirectModal';
import { AuthProviders } from '../components/AuthProviders/AuthProviders';

const Login = () => {
  const {
    onSubmit,
    email,
    register,
    formState,
    step,
    login,
    sendCode,
    onBack,
    mfaMethods,
    backendError,
    logoUrl,
    assetsEndpoint,
    redirectData,
  } = useLogin();

  return (
    <>
      <AuthWrapper logoUrl={logoUrl} assetsEndpoint={assetsEndpoint}>
        {mfaMethods ? (
          <TwoFactorAuth methods={mfaMethods} login={login} sendCode={sendCode} />
        ) : (
          <form onSubmit={onSubmit} aria-label="Login">
            {step === LoginFormStep.Password && (
              <Button
                leftIcon="fas fa-arrow-left"
                variant="link"
                mb="24"
                onClick={onBack}
                aria-label="Use a different email"
                isTruncated
              >
                {email}
              </Button>
            )}

            {backendError && (
              <Alert variant="warning" mb="16">
                {backendError}
              </Alert>
            )}

            <Text as="h1" fontSize="h4" fontWeight={600} mb="16">
              {step === LoginFormStep.Email && 'Login'}
              {step === LoginFormStep.Password && 'Enter your password'}
            </Text>

            {step === LoginFormStep.Email && (
              <Input
                {...register('username')}
                type="text"
                label="Email"
                formVariant={formState.errors.username && 'error'}
                helperText={formState.errors.username?.message}
              />
            )}

            {step === LoginFormStep.Password && (
              <>
                <Input
                  {...register('password')}
                  type="password"
                  label="Password"
                  formVariant={formState.errors.password && 'error'}
                  helperText={formState.errors.password?.message}
                />

                <Link href="/ResetPassword" target="_self" mt="16">
                  Forgot password?
                </Link>
              </>
            )}

            {step === LoginFormStep.Email && (
              <Button mt="32" width="100%" type="submit">
                Continue
              </Button>
            )}

            {step === LoginFormStep.Password && (
              <Button mt="32" width="100%" isLoading={formState.isSubmitting} type="submit">
                Log In
              </Button>
            )}
          </form>
        )}
      </AuthWrapper>

      {redirectData && <RedirectModal {...redirectData} />}
    </>
  );
};

render(
  <AuthProviders>
    <Login />

    <ToastContainer />
  </AuthProviders>,
  document.getElementById('app'),
);
